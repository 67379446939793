/* eslint-disable react-hooks/exhaustive-deps */
import FileUpload from "components/ui/FileUpload";
import SelectList from "components/ui/SelectList";
import Title from "components/ui/Title";
import { useEffect, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Button from "components/ui/Button";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { ProductApiConfig } from "api/configs/productApiConfig";
import { downloadFile, handleError } from "utils/helper";
import ProgressBar from "components/ui/ProgressBar";
import { toast } from "react-toastify";
import { useApp } from "context/appContext";
import Modal from "components/ui/Modal";

export default function BulkUpload() {
  const bulkUploadOptions = [
    { label: "moq (Minimum Order Quantity)", value: "moq" },
    { label: "Pack quantity", value: "packQty" },
    { label: "Max Order Quantity", value: "maxQty" },
    { label: "Status (Active/Inactive)", value: "status" },
    { label: "POD (Pay on delivery)", value: "pod" },
    { label: "MRP", value: "mrp" },
    { label: "Min Payment", value: "minPayment" },
    { label: "Priority", value: "priority" },
    { label: "Highlights", value: "highlights" },
    { label: "Product Tag", value: "productTag" },
    { label: "Generic name", value: "genericName" },
    { label: "Country of origin", value: "countryOfOrigin" },
    { label: "Manufacturer details", value: "manufacturerDetails" },
    { label: "Importer details", value: "importerDetails" },
    { label: "Packer details", value: "packerDetails" },
    { label: "Display size (cm)", value: "displaySize" },
  ];
  const columns = {
    moq: "ASN, Minimum Order Quantity",
    packQty: "ASN, Pack Quantity",
    maxQty: "ASN, Maximum Order Quantity",
    status: "ASN, Active Status",
    pod: "ASN, POD",
    mrp: "ASN, MRP",
    minPayment: "ASN, Min Payment",
    priority: "ASN, Priority",
    highlights: "ASN, Highlights",
    productTag: "ASN, Product Tag",
    genericName: "ASN, Generic name",
    countryOfOrigin: "ASN, Country of origin",
    manufacturerDetails: "ASN, Manufacturer details",
    importerDetails: "ASN, Importer details",
    packerDetails: "ASN, Packer details",
    displaySize: "ASN, Display size (cm)"
  };
  const [selectedBulkUpload, setSelectedBulkUpload] = useState("");
  const [file, setFile] = useState<any>();
  const [apiConfig, setApiConfig] = useState<any>();
  const { tenant } = useApp();
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { mutate: uploadFile, data: uploadRes } = useApiMutate(
    apiConfig ?? ProductApiConfig.MOQ_BULK_UPLOAD,
    {
      pathParams: { tenant },
      successCb: (data) => {
        setTimeout(() => {
          setLoader(false);
          setW(0);
          i = 0;
          toast.success("Updated successfully!", {
            autoClose: 10000,
            theme: "light",
          });
          setFile("");
          if (data?.length > 0 && selectedBulkUpload === "packQty")
            setShowPopup(true);
        }, 3000);
      },
      errorCb: (err) => {
        setTimeout(() => {
          setLoader(false);
          setW(0);
          i = 0;
          handleError(err);
        }, 3000);
      },
    }
  );
  const { refetch: getProductTagBulkUpdateTemplate, isFetching } = useApiQuery(
    ProductApiConfig.BULK_UPDATE_PRODUCT_TAG_TEMPLATE,
    {
      successCb: (data) => {
        downloadFile(data, "ProductTags.xlsx");
      },
      errorCb: handleError,
    }
  );
  const configs = {
    moq: ProductApiConfig.MOQ_BULK_UPLOAD,
    packQty: ProductApiConfig.PACK_QTY_BULK_UPLOAD,
    maxQty: ProductApiConfig.MAX_ORDER_QTY_BULK_UPLOAD,
    status: ProductApiConfig.STATUS_BULK_UPLOAD,
    pod: ProductApiConfig.POD_BULK_UPLOAD,
    mrp: ProductApiConfig.MRP_BULK_UPLOAD,
    minPayment: ProductApiConfig.MIN_PAYMENT_BULK_UPDATE,
    priority: ProductApiConfig.PRIORITY_BULK_UPDATE,
    highlights: ProductApiConfig.HIGHLIGHTS_BULK_UPDATE,
    productTag: ProductApiConfig.PRODUCT_TAG_BULK_UPDATE,
    genericName: ProductApiConfig.GENERIC_NAME_BULK_UPDATE,
    countryOfOrigin: ProductApiConfig.COUNTRY_OF_ORIGIN_BULK_UPDATE,
    manufacturerDetails: ProductApiConfig.MANUFACTURER_DETAILS_BULK_UPDATE,
    importerDetails: ProductApiConfig.IMPORTER_DETAILS_BULK_UPDATE,
    packerDetails: ProductApiConfig.PACKER_DETAILS_BULK_UPDATE,
    displaySize: ProductApiConfig.DISPLAY_SIZE_BULK_UPDATE
  };
  const [w, setW] = useState(0);
  let i = 0;

  useEffect(() => {
    setApiConfig(configs[selectedBulkUpload]);
  }, [selectedBulkUpload]);

  function makeProgress() {
    if (i < 100) {
      i++;
      setW(i);
    }
    setTimeout(() => {
      makeProgress();
    }, 30);
  }

  const handleBulkFileSubmit = async () => {
    setLoader(true);
    makeProgress();
    const data = new FormData();
    data.append("file", file);
    uploadFile(data);
  };

  return (
    <div className="p-20">
      <Title title="Bulk Update" />
      <div className="flex justify-center p-20">
        <SelectList
          list={bulkUploadOptions}
          onChange={setSelectedBulkUpload}
          selected={selectedBulkUpload}
          placeholder="--Select--"
          width="w-[400px]"
        />
      </div>
      {selectedBulkUpload === "productTag" && (
        <Button
          text={"Get File Template"}
          onButtonClick={getProductTagBulkUpdateTemplate}
          btnClass={"bg-btn my-20"}
          disabled={isFetching}
          loading={isFetching}
          loadingText="Fetching"
        />
      )}
      {selectedBulkUpload && (
        <div className="w-[50%] m-auto pt-20">
          <label className="text-20 font-bold">Choose a file</label>
          <div className="py-20">
            <FileUpload
              onChange={({ target: { files } }) => setFile(files[0])}
              fileName={file?.name}
            />
          </div>
          <div className="flex justify-center items-center">
            <ExclamationTriangleIcon className="w-30 h-30" fill="#fcb042" />
            <label className="text-18">
              Please check your column header. The first row must contain
              title.&nbsp;
              <span className="font-bold">({columns[selectedBulkUpload]})</span>
            </label>
          </div>
          {loader ? (
            <div className="py-20">
              <ProgressBar w={w} />
            </div>
          ) : (
            <Button
              text={"Submit"}
              onButtonClick={handleBulkFileSubmit}
              btnClass={"bg-btn mt-20"}
              disabled={!file || loader}
            />
          )}
          <Modal
            isOpen={showPopup}
            closeModal={() => setShowPopup(false)}
            title={""}
            onSave={() => setShowPopup(false)}
            saveBtnTxt={"OK"}
          >
            <div className="text-16">
              {typeof uploadRes === "object" && uploadRes?.join(", ")} have pack
              quantity of 1. So, the pack quantity of these products have not
              been updated!
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
