import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Input from "components/ui/Input";
import Modal from "components/ui/Modal";

export default function FilterOptions({
  showOptions,
  close,
  data,
  currAttr,
  updateAttributes,
  isEdit = false,
}) {
  const addVOption = (attrIdx, addAfter) => {
    if (isEdit)
      updateAttributes((current) => {
        const attributes = current.attributes.map((obj, i) => {
          if (attrIdx === i)
            return {
              ...obj,
              filterOptions: [
                ...obj.filterOptions.slice(0, addAfter + 1),
                { option: "" },
                ...obj.filterOptions.slice(addAfter + 1),
              ],
            };
          return obj;
        });
        return { ...current, attributes };
      });
    else
      updateAttributes((current) =>
        current.map((obj, i) => {
          if (attrIdx === i)
            return {
              ...obj,
              filterOptions: [
                ...obj.filterOptions.slice(0, addAfter + 1),
                { option: "" },
                ...obj.filterOptions.slice(addAfter + 1),
              ],
            };
          return obj;
        })
      );
  };

  const remVOption = (attrIdx, remIdx) => {
    if (isEdit)
      updateAttributes((current) => {
        const attributes = current.attributes.map((obj, i) => {
          if (attrIdx === i)
            return {
              ...obj,
              filterOptions: obj.filterOptions.filter((_, i) => i !== remIdx),
            };
          return obj;
        });
        return { ...current, attributes };
      });
    else
      updateAttributes((current) =>
        current.map((obj, i) => {
          if (attrIdx === i)
            return {
              ...obj,
              filterOptions: obj.filterOptions.filter((_, i) => i !== remIdx),
            };
          return obj;
        })
      );
  };

  const handleOptions = (ca, oi, v) => {
    if (isEdit)
      updateAttributes((current) => {
        const attributes = current.attributes.map((obj, i) => {
          if (ca === i)
            return {
              ...obj,
              filterOptions: obj.filterOptions.map((option, i) =>
                oi === i ? { option: v, type: "new" } : option
              ),
            };
          return obj;
        });
        return { ...current, attributes };
      });
    else
      updateAttributes((current) =>
        current.map((obj, i) => {
          if (ca === i)
            return {
              ...obj,
              filterOptions: obj.filterOptions.map((option, i) =>
                oi === i ? { option: v } : option
              ),
            };
          return obj;
        })
      );
  };
  return (
    <Modal
      isOpen={showOptions}
      closeModal={close}
      title={"Filter Options"}
      onSave={close}
      saveBtnTxt={"Save"}
    >
      <>
        {data?.map(({ option }, i) => {
          return (
            <div key={i} className="flex justify-between items-center p-15">
              <Input
                inputType={"text"}
                value={option}
                name={`option${i}`}
                onChange={({ target: { value } }) => {
                  if (!isEdit) handleOptions(currAttr, i, value);
                }}
                label={`Option${i + 1}`}
                disabled={isEdit}
              />

              {!isEdit && (
                <PlusCircleIcon
                  className="w-20 h-20 cursor-pointer"
                  fill="green"
                  onClick={() => addVOption(currAttr, i)}
                />
              )}
              {!isEdit && data.length > 1 && (
                <XCircleIcon
                  className="w-20 h-20 cursor-pointer"
                  fill="red"
                  onClick={() => {
                    remVOption(currAttr, i);
                  }}
                />
              )}
            </div>
          );
        })}
      </>
    </Modal>
  );
}
